import tagmanager from '../../../abstracts/tagmanager';
import baseFilter from './baseFilter';

const skiresorts = () => ({
  ...baseFilter(),

  modalId: 'skiresort-filter',

  init() {
    this.listingParams = JSON.parse(JSON.stringify(this.params ?? {})); // the params of the parent x-data
    this.filters = JSON.parse(JSON.stringify(this.params ?? {}));

    this.$el.addEventListener('form-update-value', this.onValueUpdate.bind(this));
    window.addEventListener('modal-rendered-page', this.onRenderPage.bind(this));
  },

  close(evt) {
    if (evt?.detail?.name !== this.modalId) {
      return;
    }

    let key = this.params.federalStates ? 'Federalstate' : 'Country';
    let pathArray = window.location.pathname.split('/');
    let trackingData = {
      Category: 'skiresort',
    };

    trackingData[key + 'Id'] = this.params.federalStates
      ? this.params.federalStates[0]
      : this.params.countries[0];
    trackingData[key] = pathArray[pathArray.length - 2];
    tagmanager.event(
      this.currentFilter ? 'comp_quick-filter_close' : 'comp_filter_close',
      trackingData,
    );
  },

  trackCountryChange(key, value) {
    const { page, baseUrl, countries, federalStates, ...selectedFilters } = this.params; // params of the parent x-data

    tagmanager.event(this.currentFilter ? 'comp_quick-filter_submit' : 'comp_filter_submit', {
      Category: this.currentFilter ? 'skiresort-quickfilter' : 'skiresort-filter',
      [key]: value,
      Selection: Object.keys(selectedFilters).length,
    });
  },
});

export default skiresorts;
