const map = {
  isOpen: false,

  init() {
    setTimeout(() => {
      //timeout of 1 ms needed because map leaflet isn't available earlier
      if (sessionStorage.getItem('mapIsActive') === '1') {
        this.toggleMap();
      }
    }, 20);
  },

  toggleMap() {
    if (!bergfex?.MapHandler?.showMap || !bergfex?.MapHandler?.hideMap) {
      return;
    }

    if (this.isOpen) {
      bergfex.MapHandler.hideMap();
      this.isOpen = false;
      sessionStorage.setItem('mapIsActive', '0');
      return;
    }

    bergfex.MapHandler.showMap(() => {
      this.isOpen = true;
      sessionStorage.setItem('mapIsActive', '1');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  },
};

export default map;
