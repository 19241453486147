const categorySelect = (parentUrl) => ({
  ROUTE_TYPES: '/presentation/api/component/toursFilterTypes',

  parentUrl: parentUrl,

  init() {
    this.pageBack = function () {
      this.loadPageCategories(this.parentUrl);
    }.bind(this);
  },

  loadPageTypes(categoryId, regionId, regionType) {
    let queryParams =
      '?filterInactive=false&categoryId=' +
      categoryId +
      '&regionId=' +
      regionId +
      '&regionType=' +
      regionType;

    this.$dispatch('modal-load-page', this.ROUTE_TYPES + queryParams);

    this.ignoreHistory = true;
  },

  loadPageCategories(parentUrl) {
    this.$dispatch('modal-load-page', parentUrl);
    this.history.splice(0);
    this.ignoreHistory = false;
  },

  goToSelectedItem(href) {
    window.location.href = href + window.location.search;
  },
});

export default categorySelect;
