const baseFilter = () => ({
  listingParams: {},
  filters: {},
  modalId: '',

  init() {
    this.listingParams = JSON.parse(JSON.stringify(this.params ?? {})); // the params of the parent x-data
    this.filters = JSON.parse(JSON.stringify(this.params ?? {}));

    this.$el.addEventListener('form-update-value', this.onValueUpdate.bind(this));
    window.addEventListener('modal-rendered-page', this.onRenderPage.bind(this));
  },

  async reset() {
    await this.$nextTick();

    if (!this.currentFilter) {
      for (const filter in this.filters) {
        this.resetFilterValue(filter);
      }

      this.filters = { ...this.filters, ...this.listingParams };
      this.updateFilters();

      return;
    }

    if (this.filters[this.currentFilter]) {
      this.resetFilterValue(this.currentFilter);
    }
    if (this.filters[this.currentFilter + '-from']) {
      this.resetFilterValue(this.currentFilter + '-from');
    }
    if (this.filters[this.currentFilter + '-to']) {
      this.resetFilterValue(this.currentFilter + '-to');
    }

    this.filters = { ...this.filters, ...this.listingParams };
    this.updateFilters();
  },

  resetFilterValue(key) {
    this.filters[key] = Array.isArray(this.filters[key]) ? [] : null;
  },

  filter(event) {
    this.$dispatch('filter-click-link', { filters: this.filters });
    this.$dispatch('modal-close', event);
  },

  onValueUpdate(event) {
    if (!event.detail.name) {
      return;
    }

    this.filters[event.detail.name] = event.detail.value;
  },

  async onRenderPage(event) {
    await this.$nextTick();
    this.updateFilters();
  },

  updateFilters() {
    if (!this.filters) {
      return;
    }

    for (const filter in this.filters) {
      const xModel = this.$root.querySelector('[name=' + filter + ']');
      if (!xModel || (!xModel.hasAttribute('x-model.number') && !xModel.hasAttribute('x-model'))) {
        continue;
      }
      xModel._x_model.set(this.filters[filter]);
    }
  },

  close(evt) {},
});

export default baseFilter;
