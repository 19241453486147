import tagmanager from '../../../abstracts/tagmanager';

const skiresorts = (baseUrl, filter) => ({
  endpointPath: '/presentation/api/component/skiresortsListing',
  endpointUrl: null,
  loading: false,
  error: false,
  params: { baseUrl },
  currentFilter: false,
  showPagination: true,
  showLoading: true,

  init() {
    if (!filter) {
      return;
    }
    this.params = { ...this.params, ...JSON.parse(filter) };
  },

  async paginate(event) {
    if (!event.detail.event || !event.detail.page) {
      return;
    }

    event.detail.event.preventDefault();

    if (this.loading) {
      return;
    }

    this.trackPaginate(event);

    this.params['page'] = event.detail.page ?? '';
    if (!(await this.load())) {
      return;
    }
    this.replaceHistory(event.detail.event.currentTarget.href);
  },

  async scroll(event) {
    if (!event.detail.page) {
      return;
    }

    if (this.loading) {
      return;
    }

    this.showLoading = false;

    this.params['page'] = event.detail.page ?? '';
    if (
      !(await this.append(
        event.detail.direction === 'up' ? 'afterbegin' : 'beforeend',
        event.detail.direction === 'up' ? ['endless-scroll-down'] : ['endless-scroll-up'],
      ))
    ) {
      this.showLoading = true;
      return;
    }
    this.showLoading = true;
    this.trackPaginate(event, 'skiresort-endless-scroll');
  },

  sort(event) {
    if (this.loading) {
      return;
    }

    if (!event.detail) {
      this.loadDefault();
      return;
    }

    this.trackSorting(event);

    const lastIndex = event.detail.lastIndexOf('_');
    this.params['page'] = 1;
    this.params['sortBy'] = lastIndex === -1 ? event.detail : event.detail.substring(0, lastIndex);
    this.params['sortType'] = lastIndex === -1 ? 'desc' : event.detail.substring(lastIndex + 1);
    this.replaceHistory();
    this.load();
  },

  openFilter(label, filter, url) {
    this.currentFilter = filter;

    const regionKey = this.params.federalStates ? 'Federalstate' : 'Country';
    const pathArray = window.location.pathname.split('/');
    const trackingData = {
      Category: 'skiresort',
      Selection: filter,
    };

    trackingData[regionKey] = pathArray[pathArray.length - 2];
    tagmanager.event(
      this.currentFilter ? 'comp_quick-filter_open' : 'comp_filter_open',
      trackingData,
    );

    this.$dispatch('modal-page-header', label);
    this.toggle(url);
  },

  filter(event) {
    if (this.loading) {
      return;
    }

    if (!event.detail.filters) {
      return;
    }

    this.params = { ...this.params, ...event.detail.filters };
    Object.keys(this.params).forEach((k) => this.params[k] == null && delete this.params[k]);
    this.params['page'] = 1;
    this.replaceHistory();

    this.trackFilter();

    this.load();
  },

  loadDefault() {
    this.params['page'] = 1;
    delete this.params['sortBy'];
    delete this.params['sortType'];
    this.replaceHistory();
    this.load();
  },

  replaceHistory(url = location.protocol + '//' + location.host + location.pathname) {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const newSearchUrl = new URL(url, window.location.origin);

    const mergedUrlParams = new URLSearchParams({
      ...Object.fromEntries(currentSearchParams),
      ...Object.fromEntries(newSearchUrl.searchParams),
    });

    newSearchUrl.search = mergedUrlParams.toString();
    history.replaceState({}, '', newSearchUrl);
  },

  async load() {
    if (this.$root.offsetTop < window.scrollY) {
      window.scrollTo({ top: this.$root.offsetTop, behavior: 'smooth' });
    }

    const result = await this.fetch();
    if (!result) {
      return false;
    }

    Alpine.morph(this.$root, result);

    await this.$nextTick();
    this.$dispatch('update-splide-slider', true);
    return true;
  },

  async append(insert = 'beforeend', skip) {
    let skipIds = ['skiresorts', ...skip];

    const result = await this.fetch();
    if (!result) {
      return false;
    }

    const doc = new DOMParser().parseFromString(result, 'text/html');
    const skiresorts = doc.getElementById('skiresorts');
    if (!skiresorts) {
      return false;
    }

    Alpine.morph(this.$root, result, {
      updating(el, toEl, childrenOnly, skip) {
        if (skipIds.includes(el.id) || skipIds.includes(toEl.id)) {
          skip();
        }
      },

      key(el) {
        return el.id;
      },
    });

    const heightBefore = this.$refs.skiresorts.offsetHeight;

    this.$refs.skiresorts.insertAdjacentHTML(insert, skiresorts.innerHTML);

    if (insert === 'afterbegin') {
      window.scrollBy(0, this.$refs.skiresorts.offsetHeight - heightBefore);
    }

    await this.$nextTick();
    this.$dispatch('update-splide-slider', true);

    return true;
  },

  async fetch() {
    this.error = false;
    this.loading = true;

    let result = null;
    try {
      const response = await fetch(
        this.endpointPath + '?' + new URLSearchParams(this.getUrlParams()),
      );
      const jsonData = await response.json();
      if (!jsonData.result || jsonData.error) {
        this.error = true;
        this.loading = false;
        return;
      }

      result = jsonData.result;
    } catch (error) {
      this.error = true;
      this.loading = false;
      return;
    }

    this.loading = false;
    return result;
  },

  trackPaginate(event, name = 'skiresort-paginate') {
    tagmanager.event(
      name,
      this.addLocationToTrackingData(
        {
          Category: 'skiresort',
          Selection: event.detail.page,
          Country: event.detail.country,
          CountryId: event.detail.countryId,
        },
        this.params,
      ),
    );
  },

  trackFilter() {
    const { page, baseUrl, ...selectedFilters } = this.params;

    tagmanager.event(
      this.currentFilter ? 'comp_quick-filter_submit' : 'comp_filter_submit',
      this.addLocationToTrackingData(
        {
          Category: 'skiresort',
          Selection: '' + new URLSearchParams(this.getUrlParams()),
          Filters: selectedFilters,
        },
        this.params,
      ),
    );
  },

  trackSorting(event) {
    const category = event.target.getAttribute('data-tracking-category');
    if (!category) {
      return;
    }

    tagmanager.event(
      'skiresort-sort-filter',
      this.addLocationToTrackingData(
        {
          Category: 'skiresort',
          Selection: event.target.getAttribute('data-tracking-category'),
        },
        this.params,
      ),
    );
  },

  addLocationToTrackingData(trackingData, params) {
    const pathArray = window.location.pathname.split('/');

    if (params.federalStates) {
      trackingData.FederalstateId = params.federalStates[0];
      trackingData.Federalstate = pathArray[pathArray.length - 2];
    } else {
      trackingData.CountryId = params.countries[0];
      trackingData.Country = pathArray[pathArray.length - 2];
    }

    if (trackingData.Selection && typeof trackingData.Selection === 'string') {
      trackingData.Selection = trackingData.Selection.replace(/federalstates/gi, 'Federalstate');
    }

    return trackingData;
  },

  getUrlParams() {
    let params = [];

    Object.keys(this.params).forEach((key) => {
      let value = this.params[key];
      if (Array.isArray(value)) {
        for (let i in value) {
          params.push([key + '[]', this.params[key][i]]);
        }
        return;
      }

      params.push([key, this.params[key]]);
    });

    return params;
  },
});

export default skiresorts;
