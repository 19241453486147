const rangeSlider = (min, max, valueMin, valueMax, step, name) => ({
  valueMin: null,
  valueMax: null,
  min: null,
  max: null,
  step: 10,
  minthumb: 0,
  maxthumb: 0,
  hover: null,

  init() {
    this.min = parseInt(min);
    this.max = parseInt(max);
    this.step = parseInt(step);
    this.valueMin = parseInt(valueMin) || min;
    this.valueMax = parseInt(valueMax) || max;

    this.$watch('valueMax', this.maxtrigger.bind(this));
    this.$watch('valueMin', this.mintrigger.bind(this));
  },

  mintrigger(value, oldValue) {
    if (isNaN(oldValue)) {
      return;
    }

    if (isNaN(value)) {
      value = oldValue;
    }

    if (oldValue === null) {
      this.minthumb = 0;
      return;
    }

    if (value === parseInt(oldValue)) {
      return;
    }

    if (value === null) {
      this.valueMin = this.min;
    } else {
      this.valueMin = Math.min(value, this.valueMax - this.step);
    }

    if (this.valueMin < this.min) {
      this.valueMin = this.min;
    }

    this.minthumb = ((value - this.min) / (this.max - this.min)) * 100;
    this.$dispatch('form-update-value', { name: name + '-from', value: value });
  },

  maxtrigger(value, oldValue) {
    if (isNaN(oldValue)) {
      return;
    }

    if (isNaN(value)) {
      value = oldValue;
    }

    if (oldValue === null) {
      this.maxthumb = 0;
      return;
    }

    if (value === parseInt(oldValue)) {
      return;
    }

    if (value === null) {
      this.valueMax = this.max;
    } else {
      this.valueMax = Math.max(value, parseInt(this.valueMin) + this.step);
    }

    if (this.valueMax > this.max) {
      this.valueMax = this.max;
      return;
    }

    this.maxthumb = 100 - ((value - this.min) / (this.max - this.min)) * 100;
    this.$dispatch('form-update-value', { name: name + '-to', value });
  },

  get deactivated() {
    return (
      valueMin === valueMax ||
      (this.valueMin === this.min &&
        this.valueMax === this.max &&
        this.valueMin === this.valueMax - this.step)
    );
  },
});

export default rangeSlider;
