const rating = (tourId, userRating) => ({
  canSubmit: !!userRating,
  tourId: tourId,
  userRating: userRating,
  starsRating: userRating ? userRating.rating : -1,
  rateInProgress: false,
  titleInvalid: false,
  descriptionInvalid: false,
  setRating(amount) {
    this.starsRating = amount;
    this.canSubmit = true;
    this.$refs.title.focus();
  },
  clearRating() {
    this.starsRating = -1;
    this.canSubmit = false;
  },
  async rate() {
    if (this.rateInProgress) {
      return;
    }

    this.titleInvalid = this.checkForEmailOrUrl(this.$refs.title.value);
    this.descriptionInvalid = this.checkForEmailOrUrl(this.$refs.description.value);

    if (this.titleInvalid || this.descriptionInvalid) {
      return;
    }

    this.rateInProgress = true;

    let formData = {
      rating: this.starsRating,
      title: this.$refs.title.value,
      description: this.$refs.description.value,
      csrfToken: document.querySelector('meta[name="csrfToken"]').content,
    };

    if (this.userRating) {
      formData.id = this.userRating.id;
    }

    const response = await fetch('/presentation/api/tours/' + this.tourId + '/rate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 200) {
      const data = await response.json();
      this.userRating = data.userRating;

      this.$dispatch('success');
    }

    this.rateInProgress = false;
  },
  async deleteRating() {
    if (this.rateInProgress) {
      return;
    }

    this.rateInProgress = true;

    const response = await fetch('/presentation/api/tours/' + this.tourId + '/rate', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        csrfToken: document.querySelector('meta[name="csrfToken"]').content,
      }),
    });

    if (response.status === 200) {
      this.userRating = null;
      this.starsRating = -1;

      this.$dispatch('delete');
      this.close();
    }

    this.rateInProgress = false;
  },
  checkForEmailOrUrl(text) {
    // Regular expression for URL
    const urlRegex =
      /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/;

    // Regular expression for email
    const emailRegex = /\S+@\S+\.\S+/;

    // Check if text includes a URL or an email address
    const includesUrl = urlRegex.test(text);
    const includesEmail = emailRegex.test(text);

    return includesUrl || includesEmail;
  },
});

export default rating;
